import { inject, Injectable } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { from, Observable } from 'rxjs';
import {
  doc,
  Firestore,
  collection,
  collectionData,
  query,
  orderBy,
  getDocs,
  where,
  updateDoc,
} from '@angular/fire/firestore';
import {
  Storage,
  getDownloadURL,
  ref,
  uploadBytesResumable,
} from '@angular/fire/storage';
import { Messaging } from '@angular/fire/messaging';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/services/auth.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { switchMap, tap } from 'rxjs/operators';

type ChatMessage = {
  collection_name?: string | null,
  client_id: number | null,
  counsellor_id: number | null,
  sender_id: number | null,
  sender_name: string | null,
  message?: string,
  image_url?: string,
  timestamp: string | null,
  has_seen: boolean,
  org_id: number
};

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  firestore: Firestore = inject(Firestore);
  auth: Auth = inject(Auth);
  storage: Storage = inject(Storage);
  messaging: Messaging = inject(Messaging);
  router: Router = inject(Router);
  loadingImageUrl = 'https://www.google.com/images/spin-32.gif?a';
  clientDetails: any;
  documentName: string;
  collectionName: string;
  chatDocRef: any;
  unseenMessages: any;

  constructor(private authService : AuthService,
    private httpClient: HttpClient) {
    this.authService.clientDetailsByToken$.subscribe((res: any) => {
      this.clientDetails = res;
    });

    this.documentName = `${this.clientDetails.counsellor_id}_${this.clientDetails.client_id}`;
    this.collectionName = environment.firestoreCollection;
    // Reference to the document in 'chats-local' collection
    this.chatDocRef = doc(this.firestore, this.collectionName, this.documentName);
  }

  // Loads chat message history and listens for upcoming ones.
  loadMessages = () => {
    const recentMessagesQuery = query(
      collection(this.chatDocRef, 'messages'),
      orderBy('timestamp', 'asc')
    );
    return collectionData(recentMessagesQuery);
  }

  saveImageMessage = async(file: any, messageData:ChatMessage) => {
    try {
      // 1 - Upload the image to Cloud Storage.
      const filePath = `${environment.firestoreCollection}/${this.clientDetails.counsellor_id}_${this.clientDetails.client_id}/${file.name}`;
      const newImageRef = ref(this.storage, filePath);
      const fileSnapshot = await uploadBytesResumable(newImageRef, file);

      // 2 - Generate a public URL for the file.
      const publicImageUrl = await getDownloadURL(newImageRef);

      messageData.image_url = publicImageUrl;
      
      // 3 - Store the chat message in to firestore.
      if(publicImageUrl) {
        this.sendMessageWithWebsocket(messageData).subscribe(
          (response) => {
            console.log('Message sent successfully:', response);
          },
          (error) => {
            console.error('Error sending message:', error);
          }
        );
      }
    } catch (error) {
      console.error('There was an error uploading a file to Cloud Storage:', error);
    }
  }

  sendMessageWithWebsocket(messageData): Observable<any> {
    //store the messgae or image into firestore by aws http websocket api
    const url = 'https://2crvu172d4.execute-api.ap-south-1.amazonaws.com/sendMessageWithHttp';
    return this.httpClient.post(url, messageData).pipe(res => res);
  }

  updateUnseenMessages(): Observable<any> {
    const messagesQuery = query(
      collection(this.chatDocRef, 'messages'),
      where('has_seen', '==', false),
      where('sender_id', '!=', Number(this.clientDetails.client_id))
    );
    return from(getDocs(messagesQuery)).pipe(
      tap((querySnapshot) => {
        this.unseenMessages = querySnapshot.docs.map(doc => {
          // const docId =  doc.id.split('/').pop();
          const data = doc.data();
          data.is_updated = true;
          data.doc_id = doc.id;
          return data;
        });
        console.log('Query Result:', this.unseenMessages);
      }),
      switchMap((querySnapshot) => {
        if (!querySnapshot.empty) {
          const updatePromises = querySnapshot.docs.map((doc) => {
            return updateDoc(doc.ref, { has_seen: true });
          });

          this.sendMessageWithWebsocket(this.unseenMessages).subscribe(
          (response) => {
            console.log('Message sent successfully:', response);
          },
          (error) => {
            console.error('Error sending message:', error);
          }
        );
        // return from(Promise.all(updatePromises));
      } else {
          // If there are no documents to update, return an observable of an empty array
          return from(Promise.resolve([]));
        }
      })
    );
  }

  sendChatNotification(object): Observable<any> {
    return this.httpClient
      .post('/api/Chat/send_chat_notification', object)
      .pipe((res) => res);
  }
}
