<ion-header>
  <ion-row>
    <ion-col size="2">
      <ion-icon class="back-arrow-btn" name="close-outline" (click)="closeChat()"></ion-icon>
    </ion-col>
    <ion-col size="8" class="ion-text-center heading_container">
      <ion-label class="text-primary heading"> Chat </ion-label>
    </ion-col>
  </ion-row>
</ion-header>

<ion-content>
  <div #messagesContainer class="chat-container">
    <ng-container *ngFor="let group of chatMessages">
      <div class="date-header ion-text-center text-primary ion-margin-top">
        {{ group.date }}
      </div>
      <ng-container *ngFor="let message of group.messages">
        <div class="message" [ngClass]="{
            sent: message.sender_id === message.client_id,
            received: message.sender_id !== message.client_id
          }">
          <div class="message-recieve text-primary" *ngIf="message.sender_id !== message.client_id">
            {{message.sender_name}}</div>
          <div class="message-content" *ngIf="message.message">
            <ng-container *ngIf="loadingMessages[message.timestamp]; else messageContent">
              <ion-spinner name="dots"></ion-spinner> <!-- Show loader -->
            </ng-container>
            <ng-template #messageContent>
              {{ message.message }}
              <div class="message-status">
                <ion-icon name="checkmark-done" [ngClass]="{'seen': message.has_seen, 'sent': !message.has_seen}"
                  class="message-tick"></ion-icon>
              </div>
            </ng-template>
          </div>

          <div class="flex justify-center">
            <ng-container *ngIf="loadingImages[message.timestamp]; else imageContent">
              <ion-spinner name="dots"></ion-spinner>
            </ng-container>
            <ng-template #imageContent>
                <div *ngIf="message.image_url">
                  <img class="max-w-[50vw] max-h-[30vh] rounded-md" [src]="message.image_url" alt="image" />
                </div>
            </ng-template>
          </div>
          
        </div>
        <div [ngClass]="{
          message_time_sent: message.sender_id === message.client_id,
          message_time_recieve: message.sender_id !== message.client_id
        }">{{ formatDisplayTime(message.timestamp) }}</div>
      </ng-container>
    </ng-container>
    <!-- <ng-container>
      <h5>No messages yet</h5>
    </ng-container> -->
  </div>
</ion-content>

<ion-footer>
  <!-- <ion-toolbar> -->
  <input type="file" class="file-input hidden" (change)="this.uploadImage($event)" #fileUpload>
  <ion-item class="input-container">
    <ion-button color="primary" fill="clear" class="send-button" (click)="fileUpload.click()">
      <ion-icon name="arrow-up-outline"></ion-icon>
    </ion-button>
    <ion-input [(ngModel)]="newMessage" placeholder="Type a message"
      class="message-input ion-margin-vertical"></ion-input>
    <ion-button (click)="sendMessage()" fill="clear" class="send-button ion-margin-vertical">
      <ion-icon name="send"></ion-icon>
    </ion-button>
  </ion-item>
  <!-- </ion-toolbar> -->
</ion-footer>