// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    firebaseConfig: {
      apiKey: "AIzaSyDJYl1WJrPxcsP0dEz7NQ2NwaT0ejibIQQ",
      authDomain: "niwi-ai-mobile-app.firebaseapp.com",
      databaseURL: "https://niwi-ai-mobile-app-default-rtdb.asia-southeast1.firebasedatabase.app",
      projectId: "niwi-ai-mobile-app",
      storageBucket: "prim-mobile-app",
      messagingSenderId: "80740292131",
      appId: "1:80740292131:web:8ba2d49c4ce38da00f8396",
      measurementId: "G-QKZ9VS27BQ",
      vapidKey: "BFloCHzbIK7BHpWNSTtakr3U-gS1eZ7JkOcYz1Sc0D1EapFRi2fUIDCn-SXs2BlP3P_OqLrdPk8RGsu1Jc0SPnI"
    },
    api:{
      image_url:'https://dev.niwi.ai/assets/uploads/niwiai_images/',
      crm_image_url: 'https://dev.niwi.ai/assets/uploads/niwiai_images/',
      package_url: 'https://dev.niwi.ai/assets/uploads/packages/',
      url: 'https://dev.niwi.ai',
      razorpay_key:'rzp_test_TaDqL1UYGrEHlB',
      merchant_id: '113981',
      coach_url:'https://coach.chaitanyapuri.com/api',
      ccavenue_url: 'https://app.niwi.ai/payment',
      direct_pay: 'https://app.niwi.ai/payment/domestic.php',
      international_pay: 'https://app.niwi.ai/payment/international.php',
      ccavenue_request: 'https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction',
    },
    shop:{
      enableKart: true
    },
    app:{
      app_url: 'https://app.dev.niwi.ai'
    },
    firestoreCollection: 'chats_dev'
  };

  /*
   * For easier debugging in development mode, you can import the following file
   * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
   *
   * This import should be commented out in production mode because it will have a negative impact
   * on performance if an error is thrown.
   */
  // import 'zone.js/dist/zone-error';  // Included with Angular CLI.
