<ion-app>
  <ion-router-outlet [class.safe-area]="isSafe"></ion-router-outlet>
  <currency-change></currency-change>
  <ion-fab vertical="bottom" horizontal="end" slot="fixed" class="chat_floating_button"
    (mousedown)="onDragStart($event)"
    (mousemove)="onDrag($event)"
    (mouseup)="onDragEnd($event)"
    (touchstart)="onDragStart($event)"
    (touchmove)="onDrag($event)"
    (touchend)="onDragEnd($event)"
    *ngIf="onLogin">
  <ion-fab-button (click)="openChatModal()">
    <ion-icon name="chatbubble-ellipses-outline" class="text-light"></ion-icon>
  </ion-fab-button>
</ion-fab>

</ion-app>
