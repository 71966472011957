import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(private httpClient: HttpClient) {

  }

  getMyPackage(){
    return this.httpClient.get('/api/home/my_package').pipe(res => res);
  }

  getMyCoins(){
    return this.httpClient.get('/api/coins/user_coins').pipe(res=>res);
  }
}
